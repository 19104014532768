import { request } from './request'

export function loginRequest (loginData) {
  return request({
    baseUrl: window.g.USER_URL,
    url: '/staff/login',
    method: 'post',
    data: {
      name: loginData.name,
      password: loginData.password
    }
  })
}
